.spinnerBorder {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: 0.20em solid var(--light);
    border-right: 0.20em solid transparent;
    border-radius: 50%;
    animation: spinner-border 01s linear infinite;
}
.srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

@keyframes spinner-border {
    100% {
        transform: rotate(1turn);
    }
}
