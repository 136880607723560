.nav {
    padding: 20px 0;
    color: var(--dark);
    display: flex;
    position: fixed;
    width: 100%;
    background: var(--base);
    justify-content: center;
    font-size: 1rem;
    top: 0;
    z-index: 50;
}

.navMenu {
    display: flex;
    align-items: center;
    position: relative;
}

.nav_yellow {
    background: var(--main);
}

.logo {
    max-height: 21px;
    max-width: 155px;
    width: 100%;
}

.link {
    align-items: center;
    display: flex;
    cursor: default;
    margin-left: 25px;
    color: black;
}

.navLink {
    padding: 10px 25px;
    cursor: pointer;
}

.navLink:hover {
    text-decoration: underline;
}

.navLinkMobile {
    padding: 5px 20px;
}

.container {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 25px;
    max-width: 1400px;
    width: 100%;
    z-index: 1;
}

.menuContainer {
    display: flex;
    align-items: center;
}

.menuBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 90;
}

.logoutIcon {
    position: relative;
    font-size: 1.5rem;
    transform: rotate(180deg);
    padding: 10px 10px;
    border-radius: 5px;
    margin-left: 5px;
}

.logoutIcon:hover {
    background: var(--middleGrey);
}


/*__________ MODIFIERS __________*/

.topLayer {
    z-index: 999;
}

.relative {
    position: relative;
    z-index: 0;
}

.yellow {
    background-color: var(--main);
}

.transparent {
    background-color: transparent;
}

.light {
    color: var(--light);
}

/*__________ CONTAINERS __________*/

.navMenuWide {
    display: flex;
    align-items: center;
    position: relative;
}

.navMenuWide>a {
    margin: 0 10px;
}

.navMenuWide>div {
    margin: 0 10px;
}

.navMenuNarrow {
    display: none;
    position: relative;
}

@media(max-width:800px) {
    .navMenuWide {
        display: none;
    }
    .navMenuNarrow {
        display: flex;
    }
}