.container {
    box-sizing: border-box;
    padding: 25px 25px 30px 25px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.20);
    backdrop-filter: blur(12.5px);
    -webkit-backdrop-filter: blur(12.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.title {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: Recoleta;
}
