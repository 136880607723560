.wrapper {}

.spacer70 {
    height: 70px;
    width: 100%;
}

.searchBarContainer {
    margin: 75px auto 0 auto;
    padding: 0 25px;
}

.actionContainer {
    display: flex;
    box-sizing: border-box;
    padding: 0 25px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-end;
}

.hiddenInput {
    position: fixed;
    top: -1000px;
}

.horizontalContainer {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -25px;
}

/*__________ Wrappers __________*/

.noFilesWrapper {
    width: 100%;
    height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: center;
}