.container {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px;
    height: fit-content;
    width: 15px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
    z-index: 1;
    transition: .2s;
}

.container:hover {
    background: var(--middleGrey);
    transition: .2s;
}

.container>span {
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background: rgb(194, 194, 194);
    margin: 1px;
}

/*__________ Modifieres __________*/

.container_active {
    background: var(--middleGrey);
}