.container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 25px;
    box-sizing: border-box;
    height: fit-content;
}

.container > h2 {
    font-family: recoleta;
    font-size: 1.2rem;
    margin-top: 50px;
}

.spacer70 {
    height: 70px;
    width: 100%;
}

/*__________ Modifiers __________*/

.hidden {
    display: none;
}