.wrapper {
    z-index: 999;
    padding: 25px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
} 
.background {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: var(--transparentBg);
}