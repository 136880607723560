.container {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
}

.contentContainer {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 15px;
}

.contentContainer>svg {
    flex-shrink: 0;
}

.contentContainerTop {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 15px;
    background: var(--red);
    border-radius: 5px;
    color: var(--light);
}

.contentContainerTop>svg {
    flex-shrink: 0;
}

.title {
    margin: 0;
    margin-left: 10px;
}

.container:hover {
    background: var(--middleGrey);
    transition: .2s;
}

.hider {
    position: absolute;
    overflow: hidden;
    width: 0%;
    border-radius: 5px;
    transition: linear 1.5s;
}
.hider_active {
    width: 100%;
}

/*__________ MODIFIERS __________*/

.danger {
    display: flex;
    align-items: center;
    font-weight: normal;
    color: var(--red);
    border-radius: 5px;
    cursor: default;
    margin: 0;
    transition: .2s;
}

.danger:hover {
    background: #ff68602c;
}