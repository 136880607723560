.wrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 500px;
    height: fit-content;
    max-height: 350px;
    margin: 0 25px;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    background: var(--light);
    border-radius: 16px;
    margin-bottom: 15px;
}

.inputContainer {
    display: flex;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
}