.breadCrumbContainer {
    display: flex;
    cursor: default;
    align-items: center;
    height: 70px;
}
.breadCrumb {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 7px;
    max-width: 150px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

}
.breadCrumb:hover {
    background: var(--middleGrey);
    padding: 10px 15px;
    transition: .2s;
}
