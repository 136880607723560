.container {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    color: var(--dark);
}

.container > svg {
    color: var(--dark);
}

.title {
    margin-left: 10px !important;
}

.container:hover {
    background: var(--middleGrey);
    transition: 0.2s;
}

.notification {
    position: absolute;
    top: 5px;
    left: calc(100% - 20px);
    background-color: var(--red);
    color: var(--light);
    font-size: 0.7rem;
    padding: 2px 4px;
    border-radius: 10px;
}

/*__________ MODIFIERS __________*/

.danger {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 0.9rem;
    color: var(--red);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0;
    transition: 0.2s;
}

.danger:hover {
    background: #ff68602c;
}

.red {
    cursor: default;
    display: flex;
    font-size: 0.9rem;
    color: var(--red);
    padding: 10px 15px;
    margin: 0;
}
.red > p {
    cursor: pointer;
}
.red:hover {
    background-color: transparent;
}

.stacked {
    flex-direction: column;
}

.stacked > svg {
    margin-bottom: 5px;
    font-size: 1.1em;
}

.stacked > p {
    margin: 0 !important;
    font-size: 0.9em;
}

.noIcon {
    justify-content: center;
}
.noIcon > p {
    margin: 0;
}

.pointer:hover {
    background: none;
}

.pointer > p {
    cursor: pointer;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
