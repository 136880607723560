.container {
  flex-grow: 1;
}
.input {
  border: none;
  box-sizing: border-box;
  background-color: var(--light);
  height: 50px;
  width: 100%;
  flex-grow: 1;
  padding: 0 15px 0 15px;
  border-radius: 7px;
  font-size: 16px;
  /*border: 2px solid var(--middleGrey);*/
  box-shadow: inset 0 0px 2px #00000012;
}
.input::placeholder {
  color: var(--ph);
  font-size: 16px;
}
.input:focus,
.input:active {
  outline: none;
  box-shadow: inset 0 0 2px #00000012, 0 0 0 3px var(--blueGlow)!important;
  transition: .3s;
}
.grey {
  background-color: var(--base);
}
.labelContainer {
  display: flex;
}
.labelContainer > p {
  margin-top: 4px;
  margin-bottom: 18px;
  font-size: 12px;
  font-weight: 500;
}
.requiredIndicator {
  color: var(--red);
}
