.container {
    width: 90%;
    height: 10px;
    background: var(--middleGrey);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: inset 0 1px 3px #0000000a;
}
.bar {
    border-radius: 5px;
    box-shadow: inset 0 5px 3px #ffffff2e, 0 0 3px #00000073;
    height: 100%;
    width: 0%;
    background: var(--blue);
    transition: .5s;
}