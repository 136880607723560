.container {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index:999;
    width: 100%;
    height: 100vh;
}
.image {
    margin: 0 auto;
    max-width: 80%;
    max-height: 80vh;
    border-radius: 15px;
}
.video {
    margin: 0 auto;
    max-width: 80%;
    max-height: 80vh;
    border-radius: 15px;
    background-color: var(--dark);
}
.video:focus {
    outline: none;
}
.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); 
    z-index: -1;
}
