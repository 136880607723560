.card {
    cursor: default;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 25px;
    width: 100%;
    min-width: 270px;
    max-width: 350px;
    position: relative;
    overflow: hidden;
    background: var(--light);
    text-align: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.164);
    transition: .2s;
}


.name {
    margin: 0;
    cursor: inherit;
    text-align: left;
    font-family: Recoleta;
    font-weight: bold;
    margin-bottom: 16px;
}

.title {
    cursor: inherit;
    font-family: Recoleta;
    font-size: 2.3em;
    margin: 0;
    font-weight: bold;
    margin-top: 60px;
}

.sub {
    margin: 0;
    cursor: inherit;
    font-family: Recoleta;
    margin-bottom: 50px;
}

.features {
    max-width: 170px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.feature {
    display: flex;
    align-items: center;
}

.featureText {
    cursor: inherit;
    margin-left: 10px;
}

/*__________ Modifiers __________*/

.card_yellow {
    background: var(--main);
}