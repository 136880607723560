.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.tagWrapper {
    width: 90%;
    overflow: hidden;
    max-width: 450px;
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0 0 0;
    height: 40px;
}
.tagWrapper>div:first-child {
    margin-left: 0;
}

