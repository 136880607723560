.container {
    margin: 10px 0;
    padding: 10px 25px;
    background: var(--light);
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}
.leftSide {
    display: flex;
}
.title {
    font-weight: bold;
}
.rightSide {
    display: flex;
    align-items: center;
}
.info {
    margin-right: 5px;
}
.editContainer {
    margin-right: -5px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    transition: .2s;
}
.editContainer:hover {
    display: flex;
    align-items: center;
    background: var(--middleGrey);
    padding: 5px;
    border-radius: 5px;
}

@media(max-width: 374px){
    .container {
        flex-direction: column;
    }
    .container > div:first-child > p {
        margin-bottom: -5px;
    }
}
