.wrapper {
    display: flex;
    margin-left: 20px!important;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wrapper:hover>div:first-child {
    background: rgba(54, 118, 255, 0.212);
    border: 2px solid var(--blue);
}

.wrapper:hover>svg {
    animation: nudge .4s ease;
}

.container {
    border: 2px solid var(--middleGrey);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
}

.text {
    font-family: recoleta;
    font-weight: bold;
    font-size: 1.3rem;
}

.menuBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 90;
}

@keyframes nudge {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0);
    }
}