.card {
    display: flex;
    position: relative;
    flex-direction: column;
    width: auto;
    height: 200px;
    border-radius: 16px;
    overflow: hidden;
}

.cardInner {
    border-radius: 16px;
    overflow: hidden;
    transition: .1s;
}

.videoContainer {
    cursor: pointer;
    position: relative;
    height: 150px;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    top: -5%;
    background-size: cover;
    height: 110%;
    font-size: 6rem;
}

.image>svg {
    color: var(--middleGrey);
}

.body {
    display: flex;
    padding: 12px 10px 13px 15px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    border: 2px solid var(ase);
}

.body_image {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 15px 10px 15px 15px;
}

.main {
    display: flex;
    align-items: center;
    max-width: 85%;
}

.title {
    height: 19px;
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.title_icon {
    margin-right: 10px;
    flex-shrink: 0;
}

.titleInput {
    padding: 0;
    border: none;
    font-weight: bold;
    border-radius: 3px;
    max-width: 85%;
    background-color: var(--base);
}

.titleInput:focus {
    border: none;
    outline: none;
}

.icon {
    max-height: 18px;
    margin-right: 10px;
}

/*__________ Modifiers __________*/

.is_hovered {
    box-shadow: 0 0 0 3px var(--blue);
    background: var(--blueTransparent);
}

.is_hovered > div {
    transform: scale(0.9);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
    transition: .1s;
}

.is_dragged {
    opacity: 0;
    transition: .1s;
}



