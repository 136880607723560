.button {
    height: 50px;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: var(--blue);
    color: var(--light);
    font-size: 16px;
    box-shadow: 0 2px 8px var(--blueGlow);
    cursor: pointer;
    transition: .1s;
}
.button:hover {
    background-color: var(--blueHover);
}
.button:disabled {
    background-color: var(--middleGrey);
    box-shadow: none;
    cursor: default;
}
.button:disabled:hover {
    background-color: var(--middleGrey);
}
.thin {
    height: 35px;
    box-shadow: 0 1px 4px var(--blueGlow);
}
.bold {
    font-weight: bold;
}