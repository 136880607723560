.container {
    transform-style: preserve-3d;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 5px 25px 25px 25px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    height: fit-content;
}

@media (max-width: 1200px) {
    .container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1000px) {
    .container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
    }
}

@media (max-width: 650px) {
    .container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

@media (max-width: 374px) {
    .container {
        grid-template-columns: 1fr;
    }
}

.breadcrumbContainer {
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 25px;
}

/*__________ Modifiers __________*/

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}